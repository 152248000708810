import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () => import("../views/account/register.vue"),
  //   meta: {
  //     title: "Register",
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters["auth/loggedIn"]) {
  //         // Redirect to the home page instead
  //         next({ name: "default" });
  //       } else {
  //         // Continue to the login page
  //         next();
  //       }
  //     },
  //   },
  // },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "dashboard",
    meta: { title: "Dashboard", authRequired: false },
    component: () => import("../pages/dashboard.vue"),
  },
  {
    path: "/otp",
    name: "otp verification",
    meta: {
      title: "OTP Verification",
    },
    component: () => import("../views/account/OTP.vue"),
  },
  {
    name: "resetPassword",
    path: "/resetpassword",
    meta: {
      title: "Reset Password",
    },
    component: () => import("../views/account/reset-password.vue"),
  },


  {
    path: "/settings",
    name: "settings",
    meta: { title: "Settings", authRequired: false },
    component: () => import("../pages/settings/settings.vue"),
  },
  {
    path: "/deposits",
    name: "deposits",
    meta: { title: "Deposit", authRequired: false },
    component: () => import("../pages/transactions/deposits.vue"),
  },
  {
    path: "/deposit-request",
    name: "deposit-request",
    meta: { title: "Deposit Request", authRequired: false },
    component: () => import("../pages/transactions/deposit-request.vue"),
  },
  {
    path: "/wallet-transaction",
    name: "wallet transactions",
    meta: { title: "Transactions", authRequired: false },
    component: () => import("../pages/transactions/wallet_transactions.vue"),
  },
  {
    path: "/transactions",
    name: "transactions",
    meta: { title: "Transactions", authRequired: false },
    component: () => import("../pages/transactions/transactions.vue"),
  },
  {
    path: "/transaction-single/:id",
    name: "transaction-single",
    meta: { title: "Transactions Single", authRequired: false },
    component: () => import("../pages/transactions/transaction-single.vue"),
  },
  {
    path: "/withdrawals",
    name: "withdrawals",
    meta: { title: "Withdrawals", authRequired: false },
    component: () => import("../pages/transactions/withdrawals.vue"),
  },

  {
    path: "/disputes",
    name: "disputes",
    meta: { title: "Disputes", authRequired: false },
    component: () => import("../pages/disputes/disputes.vue"),
  },
  {
    path: "/dispute-single/:id",
    name: "dispute-single",
    meta: { title: "Dispute Single", authRequired: false },
    component: () => import("../pages/disputes/dispute-single.vue"),
  },
  {
    path: "/unresolved-disputes",
    name: "unresolved-disputes",
    meta: { title: "Unresolved Disputes", authRequired: false },
    component: () => import("../pages/disputes/unresolved-disputes.vue"),
  },
  {
    path: "/users",
    name: "users",
    meta: { title: "Users", authRequired: false },
    component: () => import("../pages/users/users.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    meta: { title: "Admin", authRequired: false },
    component: () => import("../pages/users/admin.vue"),
  },
  {
    path: "/user/:id",
    name: "user single",
    meta: { title: "User Single", authRequired: false },
    component: () => import("../pages/users/user-single.vue"),
  },
  {
    path: "/kyc3-requests",
    name: "kyc3-requests",
    meta: { title: "KYC3 Requests", authRequired: false },
    component: () => import("../pages/users/kyc3-requests.vue"),
  },
  {
    path: "/kyc2-requests",
    name: "kyc2-requests",
    meta: { title: "KYC2 Requests", authRequired: false },
    component: () => import("../pages/users/kyc2-requests.vue"),
  },

//   {
//     path: "/users",
//     name: "Users",
//     meta: {
//       title: "Users",
//       authRequired: true,
//       requiredPermission:'view users'
//     },
//     component: () => import("../pages/users/Users.vue"),
//   },
//   {
//     path: "/user-settings",
//     name: "User Settings",
//     meta: {
//       title: "User Settings",
//       authRequired: false,
//     },
//     component: () => import("../pages/users/Settings.vue"),
//   },
//   {
//     path: "/permissions",
//     name: "Permissions",
//     meta: {
//       title: "Permissions",
//       authRequired: true,
//       requiredPermission:'update permission'
//     },
//     component: () => import("../pages/users/Permissions.vue"),
//   },
//   {
//     path: "/create",
//     name: "Create Users",
//     meta: {
//       title: "Users",
//       authRequired: true,
//       requiredPermission:'create user'
//     },
//     component: () => import("../pages/users/CreateUsers.vue"),
//   },
//   {
//     path: "/user/edit/:id/",
//     name: "Edit Users",
//     meta: {
//     title: "Edit User",
//     authRequired: false,
//     requiredPermission:'edit user'
//     },
//     component: () => import("../pages/users/Edit.vue"),
//   },
//   {
//     path: "/user/profile/:id",
//     name: "Profile",
//     meta: {
//     title: "Profile",
//     authRequired: false,
//     },
//     component: () => import("../pages/users/Profile.vue"),
//     children:[
//         {
//           path: "user-permission",
//           name: "userpermission",
//           meta: {
//             title: "userpermission",
//             // authRequired: true,
//             // requiredPermission:'update user permission'
//           },
//           component: () => import("../pages/users/UserPermissions.vue"),
//         },
//         {
//           path: "",
//           name: "useroverview",
//           meta: {
//             title: "overview",
//             authRequired: true,
//             requiredPermission:'view users'
//           },
//           component: () => import("../pages/users/Overview.vue"),
//         },
//     ]
//   },

//   {
//     path:"/idcard",
//     name:"student-id-card",
//     component: () => import("../pages/student/components/Idcard")
//   },
//   {
//     path: "/student/student-academic-records",
//     name: "student Academic records",
//     meta: {title: "overview",authRequired: true,requiredPermission:'view student'},
//     component: () => import("../pages/student/StudentAcademicrecords.vue"),
//   }, 
//   {
//     path: "/student/profile/:id",
//     name: "student-Profile",
//     meta: {title: "overview",authRequired: true,requiredPermission:'view student'},
//     component: () => import("../pages/student/StudentProfile.vue"),
//     children:[
//       {
//         path:"",
//         name:"student-overview",
//         component: () => import("../pages/student/Overview")
//       },
//       {
//         path:"idcard",
//         name:"student-card",
//         component: () => import("../pages/student/components/Idcard")
//       },
//       {
//         path:"courses",
//         name:"student-courses",

//         meta: {title: "overview",authRequired: true,requiredPermission:'view student'},
//         component: () => import("../pages/student/Courses")
//       },
//       {
//         path:"results",
//         name:"student-results",
//         component: () => import("../pages/student/Results.vue")
//       },
//       {
//         path:"fees",
//         name:"student-fees",
//         component: () => import("../pages/student/Fees.vue")
//       },
//       {
//         path:"payments",
//         name:"student-payments",
//         component: () => import("../pages/student/components/PaymentsMade.vue")
//       },
//       {
//         path:"edit-student",
//         name:"Edit Student",
//         meta:{ title: "EditStudent", authRequired: true,requiredPermission:'edit student' },

//         component: () => import("../pages/student/setting"),
//         children:[
//           {
//             path: "",
//             name: "Student Edit",
//             meta: { title: "student", authRequired: false },
//             component: () => import("../pages/student/StudentSettings"),
//           },
//           {
//             path: "academic-info",
//             name: "edit Academic Info",
//             meta: { title: "academic", authRequired: false },
//             component: () => import("../pages/student/StudAcadSettings"),
//           },
//           {
//             path: "document-info",
//             name: "edit Doc Info",
//             meta: { title: "student documents", authRequired: false },
//             component: () => import("../pages/student/components/StudentDocs.vue"),
//           },
//           {
//             path: "transfer-student",
//             name: "Transfer Student",
//             meta: { title: "Transfer Student", authRequired: false },
//             component: () => import("../pages/student/components/Transfer.vue"),
//           },
//           {
//             path: "suspend-student",
//             name: "Suspend Student",
//             meta: { title: "suspend student", authRequired: true, requiredPermission: 'delete student' },
//             component: () => import("../pages/student/components/SuspendStudent.vue"),
//           },
//           {
//             path: "delete-student",
//             name: "delete Student",
//             meta: { title: "delete student", authRequired: true, requiredPermission: 'delete student' },
//             component: () => import("../pages/student/components/DeleteStudent.vue"),
//           },
//         ]
//       },
//     ]
//   }, 
//   {
//     path: "/student/student-academic-records",
//     name: "student Academic records",
//     component: () => import("../pages/student/StudentAcademicrecords.vue"),
//   }, 
//   {
//     path:"/document-validation",
//     name: "document validation",
//     component: () => import("../pages/student/DocValidation")
//   },
//   {
//     path: "/student/manage-student",
//     name: "Manage student",

//     meta: {title: "overview",authRequired: true,requiredPermission:'manage students'},
//     component: () => import("../pages/student/ManageStudent.vue"),
//   }, 
//   {
//     path: "/student/add-student",
//     name: "Add student",
//     meta: {title: "overview",authRequired: true,requiredPermission:'add students'},
//     component: () => import("../pages/student/AddStudent.vue"),
//   }, 
//   {
//     path: "/student/import-students",
//     name: "Import student",

//     meta: {title: "overview",authRequired: true,requiredPermission:'import student'},
//     component: () => import("../pages/student/ImportStudents.vue"),
//   }, 
//   {
//     path: "/student/managesinglestudent",
//     name: "Manage single student",

//     meta: {title: "overview",authRequired: true,requiredPermission:'manage student'},
//     component: () => import("../pages/student/ManageSingleStudent.vue"),
//   }, 
//   {
//     path: "/student/enrollmentstatistics",
//     name: "enrollment statistics",

//     meta: {title: "overview",authRequired: true,requiredPermission:'student bio data'},
//     component: () => import("../pages/student/EnrollmentStatistics.vue"),
//   }, 
//   {
//     path: "/student/edit-bio-data",
//     name: "edit bio data",
//     component: () => import("../pages/student/EditBioData.vue"),
//   }, 
//   {
//     path: "/student/student-bio-data",
//     name: "student bio data",

//     meta: {title: "overview",authRequired: true,requiredPermission:'student bio data'},
//     component: () => import("../pages/student/StudentBiodata.vue"),
//   },
//   {
//     path: "/logout",
//     name: "logout",
//     meta: {
//       title: "Logout", authRequired: false,
//       beforeResolve(routeTo, routeFrom, next) {
//         localStorage.clear();
//         sessionStorage.clear();
//         next();
//       },
//     },
//     component: () => import("../views/auth/logout/basic")
//   },
//   {
//     path: "/dashboard",
//     name: "default",
//     meta: {
//       title: "Dashboard",
//       authRequired: false,
//     },
//     component: () => import("../pages/dashboard/index"),
//   },
//   {
//     path: "/app-settings",
//     name: "app-settings",
//     meta: { title: "App Settings", authRequired: false },
//     component: () => import("../pages/AppSettings"),
//   },
//   {
//     path:"/pages/profile-setting",
//     name:"settings",
//     meta:{ title: "Settings", authRequired: false },
//     component: () => import("../pages/student/setting"),
//     children:[
//       {
//         path: "",
//         name: "Student",
//         meta: { title: "student", authRequired: false },
//         component: () => import("../pages/student/StudentSettings"),
//       },
//       {
//       path: "acad",
//       name: "Academic",
//       meta: { title: "academic", authRequired: false },
//       component: () => import("../pages/student/StudAcadSettings"),
//     },
//     ]
//   },
//   {
//     path: "/activities",
//     name: "activities",
//     meta: { title: "Activities", authRequired: false },
//     component: () => import("../pages/ActivitiesPage"),
//   },
//   {
//     path: "/create-academic-year",
//     name: "create-academic-year",
//     meta: { title: "Create Academic Year", authRequired: true ,requiredPermission:'manage academic year'},
//     component: () => import("../pages/academic_year/CreateAcademicYear"),
//   },
//   {
//     path: "/admission/statistics",
//     name: "admission-statistics",
//     meta: { title: "Admission Statistics", authRequired: true, requiredPermission:'admission statistics' },
//     component: () => import("../pages/admissions/AdmissionStatistics"),
//   },
//   {
//     path: "/admission/applicant-statistics",
//     name: "applicant-list",
//     meta: { title: "Applicant Statistics", authRequired: false },
//     component: () => import("../pages/admissions/ApplicantList"),
//   },
//   {
//     path: "/applicant-statistics",
//     name: "applicant-statistics",
//     meta: { title: "Applicant Statistics", authRequired: true,requiredPermission:'admission statistics' },
//     component: () => import("../pages/admissions/ApplicantStatistics"),
//   },
//   {
//     path: "/admission/applicant-statistics/:id",
//     name: "Applicant Information",
//     meta: { title: "Student Information", authRequired: false },
//     component: () => import("../pages/admissions/ValidateApplicant"),
//   },
//   {
//     path: "/admission/list",
//     name: "admission-list",
//     meta: { title: "Admission List", authRequired: true,requiredPermission:'admission list' },
//     component: () => import("../pages/admissions/AdmissionList"),
//   },
//   {
//     path: "/admission/undergraduate-files",
//     name: "undergraduate-files",
//     meta: { title: "Undergraduate List", authRequired: false },
//     component: () => import("../pages/admissions/UndergraduateApplicant"),
//   },
//   {
//     path: "/admission/verify-applicant",
//     name: "verify-applicant",
//     meta: { title: "Verify Applicant", authRequired: true, requiredPermission:'validate applicant' },
//     component: () => import("../pages/admissions/VerifyApplicant"),
//   },
//   {
//     path: "/schools",
//     name: "Schools",
//     meta: { title: "Schools", authRequired: true , 
//     requiredPermission:'school management' },
//     component: () => import("../pages/academic_structure/schools/Schools"),
//   },
//   {
//     path: "/academic-year",
//     name: "academic-year",
//     meta: { title: "Academic Year", authRequired: true ,requiredPermission:  'manage academic year' },
//     component: () => import("../pages/AcademicYear"),
//     children: [
//       {
//         path: "",
//         name: "Overview1",
//         meta: { title: "Overview1", authRequired: false },
//         component: () => import("../pages/academic_year/Overview"),
//       },
//       {
//         path: "manageAcademicYear",
//         name: "ManageAcademicYear",
//         meta: { title: "ManageAcademicYear", authRequired: true },
//         component: () => import("../pages/academic_year/ManageAcademicYear"),
//       },
//       {
//         path: "stats",
//         name: "Stats",
//         meta: { title: "Stats", authRequired: false },
//         component: () => import("../pages/academic_year/Stats"),
//       },
//     ]
//   },
//   {
//     path: "/school/:id/",
//     name: "School Single",
//     meta: { title: "School Profile", authRequired: true, requiredPermission:'school management' },
//     component: () => import("../pages/academic_structure/schools/SchoolSingle"),
//     children: [
//       {
//         path: "",
//         name: "School Overview",
//         meta: { title: "overview", authRequired: false },
//         component: () => import("../pages/academic_structure/departments/Overview"),
//       },
//       {
//         path: "departments",
//         name: "Departments",
//         meta: { title: "Departments", authRequired: false },
//         component: () => import("../pages/academic_structure/departments/Departments"),
//       },
//       {
//         path: "import-departments",
//         name: "Import Departments",
//         meta: { title: "Import Departments", authRequired: false },
//         component: () => import("../pages/academic_structure/departments/ImportDepartments"),
//       },
//       {
//         path: "options",
//         name: "Options",
//         meta: { title: "Options", authRequired: false },
//         component: () => import("../pages/academic_structure/departments/Options"),
//       },
//       {
//         path: "courses",
//         name: "Level-Courses",
//         meta: { title: "Level Courses", authRequired: false},
//         component: () => import("../pages/academic_structure/programs/Courses"),
//       }, 
//       {
//         path: "levels",
//         name: "school-levels",
//         meta: { title: "Program sLevels", authRequired: false},
//         component: () => import("../pages/academic_structure/programs/Levels"),
       
//       },
//       {
//         path: "import-levels",
//         name: "import school-levels",
//         meta: { title: "Import Program sLevels", authRequired: false},
//         component: () => import("../pages/academic_structure/programs/ImportLevels"),
       
//       },
//       {
//         path: "student",
//         name: "school Students",
//         meta: { title: "School Student", authRequired: false},
//         component: () => import("../pages/academic_structure/programs/student/Students"),
       
//       },
//       {
//         path: "import-student",
//         name: "import school Students",
//         meta: { title: "Import School Student", authRequired: false},
//         component: () => import("../pages/academic_structure/programs/student/ImportStudents"),
       
//       },
//       {
//         path: "programs",
//         name: "Programss",
//         meta: { title: "Programs", authRequired: false },
//         component: () => import("../pages/academic_structure/departments/Programs"),
//       },
//       {
//         path: "import-programs",
//         name: "Import Programss",
//         meta: { title: " ImportPrograms", authRequired: false },
//         component: () => import("../pages/academic_structure/programs/ImportPrograms"),
//       },
//     ]
//   },
//   // {
//   //   path: "/options",
//   //   name: "Options",
//   //   meta: { title: "Options", authRequired: false },
//   //   component: () => import("../pages/academic_structure/departments/Options"),
//   // },
//   // {
//   //   path: "/levels",
//   //   name: "levels",
//   //   meta: { title: "Program Levels", authRequired: false},
//   //   component: () => import("../pages/academic_structure/programs/Levels"),
//   // },
//   {
//     path: "/calendar",
//     name: "calendar",
//     meta: { title: "Calendar", authRequired: false },
//     component: () => import("../views/calendar/index"),
//   },
//   {
//     path: "/chat",
//     name: "chat",
//     meta: { title: "Chat", authRequired: false },
//     component: () => import("../views/apps/chat"),
//   },
  
//   {
//     path: "/apps-file-manager",
//     name: "file manager",
//     meta: { title: "File Manager", authRequired: false },
//     component: () => import("../views/apps/filemanager"),
//   },
//   {
//     path: "/apps-todo",
//     name: "To Do List",
//     meta: { title: "To Do List", authRequired: false },
//     component: () => import("../views/apps/todo"),
//   },
//   {
//     path: "/mailbox",
//     name: "mailbox",
//     meta: { title: "Mailbox", authRequired: false },
//     component: () => import("../views/apps/email/mailbox"),
//   },
//   {
//     path: "/email/email-basic",
//     name: "email-basic",
//     meta: { title: "Basic Action", authRequired: false },
//     component: () => import("../views/apps/email/email-basic"),
//   },
//   {
//     path: "/email/email-ecommerce",
//     name: "email-ecommerce",
//     meta: { title: "Ecommerce Action", authRequired: false },
//     component: () => import("../views/apps/email/email-ecommerce"),
//   },
//   {
//     path: "/apps/nft-auction",
//     name: "auction",
//     meta: { title: "Live Auction", authRequired: false },
//     component: () => import("../views/apps/nft/nft-auction"),
//   },
//   {
//     path: "/apps/nft-collection",
//     name: "collection",
//     meta: { title: "Collactions", authRequired: false },
//     component: () => import("../views/apps/nft/nft-collection"),
//   },
//   {
//     path: "/apps/nft-create",
//     name: "create",
//     meta: { title: "Create", authRequired: false },
//     component: () => import("../views/apps/nft/nft-create"),
//   },
//   {
//     path: "/apps/nft-creators",
//     name: "creators",
//     meta: { title: "Creators", authRequired: false },
//     component: () => import("../views/apps/nft/nft-creators"),
//   },
//   {
//     path: "/apps/nft-explore",
//     name: "explore",
//     meta: { title: "Explore Now", authRequired: false },
//     component: () => import("../views/apps/nft/nft-explore"),
//   },
//   {
//     path: "/apps/nft-item-detail",
//     name: "item-detail",
//     meta: { title: "Item Detail", authRequired: false },
//     component: () => import("../views/apps/nft/nft-item-detail"),
//   },
//   {
//     path: "/apps/nft-marketplace",
//     name: "marketplace",
//     meta: { title: "Marketplace", authRequired: false },
//     component: () => import("../views/apps/nft/nft-marketplace"),
//   },
//   {
//     path: "/apps/nft-ranking",
//     name: "ranking",
//     meta: { title: "Ranking", authRequired: false },
//     component: () => import("../views/apps/nft/nft-ranking"),
//   },
//   {
//     path: "/apps/nft-wallet",
//     name: "nft-wallet",
//     meta: { title: "Wallet", authRequired: false },
//     component: () => import("../views/apps/nft/nft-wallet"),
//   },
//   {
//     path: "/apps/projects-list",
//     name: "projects-list",
//     meta: { title: "Projects List", authRequired: false },
//     component: () => import("../views/apps/projects/list"),
//   },
//   {
//     path: "/apps/projects-overview",
//     name: "projects Overview",
//     meta: { title: "Projects-overview", authRequired: false },
//     component: () => import("../views/apps/projects/overview"),
//   },
//   {
//     path: "/apps/projects-create",
//     name: "projects-create",
//     meta: { title: "projects-create", authRequired: false },
//     component: () => import("../views/apps/projects/create"),
//   },
//   {
//     path: "/apps/tasks-details",
//     name: "tasks-details",
//     meta: { title: "Tasks Details", authRequired: false },
//     component: () => import("../views/apps/tasks/details"),
//   },
//   {
//     path: "/apps/tasks-kanban",
//     name: "tasks-kanban",
//     meta: { title: "Tasks Kanban", authRequired: false },
//     component: () => import("../views/apps/tasks/kanban"),
//   },
//   {
//     path: "/apps/tasks-list-view",
//     name: "tasks-list-view",
//     meta: { title: "Taks List", authRequired: false },
//     component: () => import("../views/apps/tasks/list-view"),
//   },
//   {
//     path: "/apps/tickets-details",
//     name: "tickets-details",
//     meta: { title: "Ticket Details", authRequired: false },
//     component: () => import("../views/tickets/details"),
//   },
//   {
//     path: "/apps/tickets-list",
//     name: "tickets-list",
//     meta: { title: "Tickets List", authRequired: false },
//     component: () => import("../views/tickets/list"),
//   },
//   {
//     path: "/apps/crm-contacts",
//     name: "crm-contacts",
//     meta: { title: "CRM Contacts", authRequired: false },
//     component: () => import("../views/apps/crm/contacts"),
//   },
//   {
//     path: "/apps/crm-companies",
//     name: "crm-companies",
//     meta: { title: "CRM companies", authRequired: false },
//     component: () => import("../views/apps/crm/companies"),
//   },
//   {
//     path: "/apps/crm-deals",
//     name: "crm-deals",
//     meta: { title: "CRM Deals", authRequired: false },
//     component: () => import("../views/apps/crm/deals"),
//   },
//   {
//     path: "/apps/crm-leads",
//     name: "crm-leads",
//     meta: { title: "CRM Leads", authRequired: false },
//     component: () => import("../views/apps/crm/leads"),
//   },
//   {
//     path: "/ecommerce/customers",
//     name: "customers",
//     meta: { title: "Customers", authRequired: false },
//     component: () => import("../views/apps/ecommerce/customers"),
//   },
//   {
//     path: "/ecommerce/products",
//     name: "products",
//     meta: { title: "Products", authRequired: false },
//     component: () => import("../views/apps/ecommerce/products"),
//   },
//   {
//     path: "/ecommerce/product-details",
//     name: "product-detail",
//     meta: { title: "Product Details", authRequired: false },
//     component: () => import("../views/apps/ecommerce/product-details"),
//   },
//   {
//     path: "/ecommerce/orders",
//     name: "orders",
//     meta: { title: "Orders", authRequired: false },
//     component: () => import("../views/apps/ecommerce/orders"),
//   },
//   {
//     path: "/ecommerce/order-details",
//     name: "order-details",
//     meta: { title: "Order Details", authRequired: false },
//     component: () => import("../views/apps/ecommerce/order-details"),
//   },
//   {
//     path: "/ecommerce/add-product",
//     name: "add-product",
//     meta: { title: "Create Product", authRequired: false },
//     component: () => import("../views/apps/ecommerce/add-product"),
//   },
//   {
//     path: "/ecommerce/shopping-cart",
//     name: "shopping cart",
//     meta: { title: "Shopping Cart", authRequired: false },
//     component: () => import("../views/apps/ecommerce/shopping-cart"),
//   },
//   {
//     path: "/ecommerce/checkout",
//     name: "checkout",
//     meta: { title: "Check Out", authRequired: false },
//     component: () => import("../views/apps/ecommerce/checkout"),
//   },
//   {
//     path: "/ecommerce/sellers",
//     name: "sellers",
//     meta: { title: "Sellers", authRequired: false },
//     component: () => import("../views/apps/ecommerce/sellers"),
//   },
//   {
//     path: "/ecommerce/seller-details",
//     name: "seller-details",
//     meta: { title: "Seller Details", authRequired: false },
//     component: () => import("../views/apps/ecommerce/seller-details"),
//   },
//   {
//     path: "/crypto/buy-sell",
//     name: "buy/sell",
//     meta: { title: "Buy & Sell", authRequired: false },
//     component: () => import("../views/apps/crypto/buy-sell"),
//   },
//   {
//     path: "/crypto/kyc",
//     name: "kyc",
//     meta: { title: "KYC Application", authRequired: false },
//     component: () => import("../views/apps/crypto/kyc"),
//   },
//   {
//     path: "/crypto/ico",
//     name: "ico",
//     meta: { title: "ICO List", authRequired: false },
//     component: () => import("../views/apps/crypto/ico"),
//   },
//   {
//     path: "/crypto/orders",
//     name: "crypto-orders",
//     meta: { title: "Orders", authRequired: false },
//     component: () => import("../views/apps/crypto/orders"),
//   },
//   {
//     path: "/crypto/wallet",
//     name: "wallet",
//     meta: { title: "My Wallet", authRequired: false },
//     component: () => import("../views/apps/crypto/wallet"),
//   },
//   {
//     path: "/crypto/transactions",
//     name: "transactions",
//     meta: { title: "Transactions", authRequired: false },
//     component: () => import("../views/apps/crypto/transactions"),
//   },
//   {
//     path: "/invoices/detail/:id?",
//     name: "invoice-detail",
//     meta: { title: "Invoice Details", authRequired: false },
//     component: () => import("../views/apps/invoices/details"),
//   },
//   {
//     path: "/invoices/list",
//     name: "invoice-list",
//     meta: { title: "Invoice List", authRequired: false },
//     component: () => import("../views/apps/invoices/list"),
//   },
//   {
//     path: "/invoices/create",
//     name: "invoice-create",
//     meta: { title: "Create Invoice", authRequired: false },
//     component: () => import("../views/apps/invoices/create"),
//   },
//   {
//     path: "/jobs/application",
//     name: "job-application",
//     meta: { title: "Job Application", authRequired: false },
//     component: () => import("../views/apps/jobs/application"),
//   },
//   {
//     path: "/jobs/candidate-grid",
//     name: "job-candidate-grid",
//     meta: { title: "Candidate Grid", authRequired: false },
//     component: () => import("../views/apps/jobs/candidate-grid"),
//   },
//   {
//     path: "/jobs/candidate-lists",
//     name: "job-candidate-lists",
//     meta: { title: "Candidate Lists", authRequired: false },
//     component: () => import("../views/apps/jobs/candidate-lists"),
//   },
//   {
//     path: "/jobs/categories",
//     name: "job-categories",
//     meta: { title: "Job Categories", authRequired: false },
//     component: () => import("../views/apps/jobs/categories"),
//   },
//   {
//     path: "/jobs/companies-list",
//     name: "job-companies-list",
//     meta: { title: "Companies List", authRequired: false },
//     component: () => import("../views/apps/jobs/companies-list"),
//   },
//   {
//     path: "/jobs/details",
//     name: "job-details",
//     meta: { title: "Job Details", authRequired: false },
//     component: () => import("../views/apps/jobs/details"),
//   },
//   {
//     path: "/jobs/grid-lists",
//     name: "job-grid-lists",
//     meta: { title: "Grid Lists", authRequired: false },
//     component: () => import("../views/apps/jobs/grid-lists"),
//   },
//   {
//     path: "/jobs/lists",
//     name: "job-lists",
//     meta: { title: "Job Lists", authRequired: false },
//     component: () => import("../views/apps/jobs/lists"),
//   },
//   {
//     path: "/jobs/new",
//     name: "job-new",
//     meta: { title: "New Job", authRequired: false },
//     component: () => import("../views/apps/jobs/new"),
//   },
//   {
//     path: "/jobs/statistics",
//     name: "job-statistics",
//     meta: { title: "Job Statistics", authRequired: false },
//     component: () => import("../views/apps/jobs/statistics"),
//   },
//   {
//     path: "/apps-api-key",
//     name: "API Key",
//     meta: { title: "API Key", authRequired: false },
//     component: () => import("../views/apps/apikey"),
//   },

//   {
//     path: "/ui/alerts",
//     name: "Alerts",
//     meta: {
//       title: "Alerts", authRequired: false,
//     },
//     component: () => import("../views/ui/alerts"),
//   },
//   {
//     path: "/ui/buttons",
//     name: "Buttons",
//     meta: {
//       title: "Buttons", authRequired: false,
//     },
//     component: () => import("../views/ui/buttons"),
//   },
//   {
//     path: "/ui/cards",
//     name: "Cards",
//     meta: { title: "Cards", authRequired: false },
//     component: () => import("../views/ui/cards"),
//   },
//   {
//     path: "/ui/carousel",
//     name: "Carousel",
//     meta: { title: "Carousel", authRequired: false },
//     component: () => import("../views/ui/carousel"),
//   },
//   {
//     path: "/ui/dropdowns",
//     name: "Dropdowns",
//     meta: { title: "Dropdowns", authRequired: false },
//     component: () => import("../views/ui/dropdowns"),
//   },
//   {
//     path: "/ui/grid",
//     name: "Grid",
//     meta: { title: "Grid", authRequired: false },
//     component: () => import("../views/ui/grid"),
//   },
//   {
//     path: "/ui/images",
//     name: "Images",
//     meta: { title: "Images", authRequired: false },
//     component: () => import("../views/ui/images"),
//   },
//   {
//     path: "/ui/modals",
//     name: "Modals",
//     meta: { title: "Modals", authRequired: false },
//     component: () => import("../views/ui/modals"),
//   },
//   {
//     path: "/ui/offcanvas",
//     name: "offcanvas",
//     meta: { title: "Offcanvas", authRequired: false },
//     component: () => import("../views/ui/offcanvas"),
//   },
//   {
//     path: "/ui/progress",
//     name: "Progress",
//     meta: { title: "Progress", authRequired: false },
//     component: () => import("../views/ui/progress"),
//   },
//   {
//     path: "/ui/placeholders",
//     name: "Placeholders",
//     meta: { title: "Placeholders", authRequired: false },
//     component: () => import("../views/ui/placeholders"),
//   },
//   {
//     path: "/ui/accordions",
//     name: "accordions",
//     meta: { title: "Accordions", authRequired: false },
//     component: () => import("../views/ui/accordions"),
//   },
//   {
//     path: "/ui/tabs",
//     name: "tabs",
//     meta: { title: "Tabs", authRequired: false },
//     component: () => import("../views/ui/tabs"),
//   },
//   {
//     path: "/ui/typography",
//     name: "Typography",
//     meta: { title: "Typography", authRequired: false },
//     component: () => import("../views/ui/typography"),
//   },
//   {
//     path: "/ui/embed-video",
//     name: "embed-video",
//     meta: { title: "Embed Video", authRequired: false },
//     component: () => import("../views/ui/embed-video"),
//   },
//   {
//     path: "/ui/ribbons",
//     name: "ribbons",
//     meta: { title: "Ribbons", authRequired: false },
//     component: () => import("../views/ui/ribbons"),
//   },
//   {
//     path: "/ui/lists",
//     name: "lists",
//     meta: { title: "Lists", authRequired: false },
//     component: () => import("../views/ui/lists"),
//   },
//   {
//     path: "/ui/utilities",
//     name: "utilities",
//     meta: { title: "Utilities", authRequired: false },
//     component: () => import("../views/ui/utilities"),
//   },
//   {
//     path: "/ui/notifications",
//     name: "notifications",
//     meta: { title: "Notifications", authRequired: false },
//     component: () => import("../views/ui/notifications"),
//   },
//   {
//     path: "/ui/general",
//     name: "General",
//     meta: { title: "General", authRequired: false },
//     component: () => import("../views/ui/general"),
//   },
//   {
//     path: "/ui/colors",
//     name: "Colors",
//     meta: { title: "Colors", authRequired: false },
//     component: () => import("../views/ui/colors"),
//   },
//   {
//     path: "/ui/badges",
//     name: "badges",
//     meta: { title: "Badges", authRequired: false },
//     component: () => import("../views/ui/badges"),
//   },
//   {
//     path: "/ui/media",
//     name: "media",
//     meta: { title: "Media", authRequired: false },
//     component: () => import("../views/ui/media"),
//   },
//   {
//     path: "/widgets",
//     name: "widgets",
//     meta: { title: "Widgets", authRequired: false },
//     component: () => import("../views/widgets/index"),
//   },
//   {
//     path: "/icons/boxicons",
//     name: "boxicons-icon",
//     meta: { title: "Boxicons", authRequired: false },
//     component: () => import("../views/icons/boxicons"),
//   },
//   {
//     path: "/icons/materialdesign",
//     name: "Material Design",
//     meta: { title: "material Design", authRequired: false },
//     component: () => import("../views/icons/materialdesign"),
//   },
//   {
//     path: "/icons/feather",
//     name: "feather",
//     meta: { title: "Feather", authRequired: false },
//     component: () => import("../views/icons/feather"),
//   },
//   {
//     path: "/icons/lineawesome",
//     name: "lineawesome",
//     meta: { title: "Line Awesome", authRequired: false },
//     component: () => import("../views/icons/lineawesome"),
//   },
//   {
//     path: "/icons/remix",
//     name: "remix",
//     meta: { title: "Remix", authRequired: false },
//     component: () => import("../views/icons/remix"),
//   },
//   {
//     path: "/icons/crypto",
//     name: "crypto",
//     meta: { title: "Crypto", authRequired: false },
//     component: () => import("../views/icons/crypto"),
//   },
//   {
//     path: "/tables/basic",
//     name: "basic-table",
//     meta: { title: "Basic Table", authRequired: false },
//     component: () => import("../views/tables/basic"),
//   },
//   {
//     path: "/tables/gridjs",
//     name: "gridjs",
//     meta: { title: "Grid JS", authRequired: false },
//     component: () => import("../views/tables/grid/index"),
//   },
//   {
//     path: "/form/advanced",
//     name: "form-advanced",
//     meta: { title: "Form Advance", authRequired: false },
//     component: () => import("../views/forms/advanced"),
//   },
//   {
//     path: "/form/elements",
//     name: "form-elements",
//     meta: { title: "Form Elements", authRequired: false },
//     component: () => import("../views/forms/elements"),
//   },
//   {
//     path: "/form/layouts",
//     name: "Form Layouts",
//     meta: { title: "Form Layouts", authRequired: false },
//     component: () => import("../views/forms/layouts"),
//   },
//   {
//     path: "/form/editors",
//     name: "Form Editors",
//     meta: { title: "Form Editors", authRequired: false },
//     component: () => import("../views/forms/editors"),
//   },
//   {
//     path: "/form/file-uploads",
//     name: "File Uploads",
//     meta: { title: "File Uploads", authRequired: false },
//     component: () => import("../views/forms/file-uploads"),
//   },
//   {
//     path: "/form/validation",
//     name: "Form Validation",
//     meta: { title: "Form Validation", authRequired: false },
//     component: () => import("../views/forms/validation"),
//   },
//   {
//     path: "/form/wizard",
//     name: "Form Wizard",
//     meta: { title: "Form Wizard", authRequired: false },
//     component: () => import("../views/forms/wizard"),
//   },
//   {
//     path: "/form/masks",
//     name: "form-mask",
//     meta: { title: "Form Mask", authRequired: false },
//     component: () => import("../views/forms/masks"),
//   },
//   {
//     path: "/form/pickers",
//     name: "form-pickers",
//     meta: { title: "Form Picker", authRequired: false },
//     component: () => import("../views/forms/pickers"),
//   },
//   {
//     path: "/form/range-sliders",
//     name: "form-range-slider",
//     meta: { title: "Form Range Slider", authRequired: false },
//     component: () => import("../views/forms/range-sliders"),
//   },
//   {
//     path: "/form/select",
//     name: "form-select",
//     meta: { title: "Form Select", authRequired: false },
//     component: () => import("../views/forms/select"),
//   },
//   {
//     path: "/form/checkboxs-radios",
//     name: "form-checkboxs-radio",
//     meta: { title: "Checkboxs & Radios", authRequired: false },
//     component: () => import("../views/forms/checkboxs-radios"),
//   },
//   {
//     path: "/landing",
//     name: "landing",
//     meta: { title: "Landing", authRequired: false },
//     component: () => import("../views/landing/index"),
//   },
//   {
//     path: "/nft-landing",
//     name: "nft-landing",
//     meta: { title: "Landing", authRequired: false },
//     component: () => import("../views/landing/nft-landing"),
//   },
//   {
//     path: "/job-landing",
//     name: "job-landing",
//     meta: { title: "Job Landing", authRequired: false },
//     component: () => import("../views/landing/job-landing"),
//   },
//   {
//     path: "/pages/starter",
//     name: "starter",
//     meta: { title: "Starter", authRequired: false },
//     component: () => import("../views/pages/starter"),
//   },
//   {
//     path: "/pages/profile",
//     name: "profile",
//     meta: { title: "Profile", authRequired: false },
//     component: () => import("../views/pages/profile/simple"),
//   },
//   {
//     path: "/pages/profile-setting",
//     name: "profile-setting",
//     meta: { title: "Setting", authRequired: false },
//     component: () => import("../views/pages/profile/setting"),
//   },
//   {
//     path: "/pages/maintenance",
//     name: "maintenance",
//     meta: { title: "Maintanance", authRequired: false },
//     component: () => import("../views/pages/maintenance"),
//   },
//   {
//     path: "/pages/coming-soon",
//     name: "coming-soon",
//     meta: { title: "Comming Soon", authRequired: false },
//     component: () => import("../views/pages/coming-soon"),
//   },
//   {
//     path: "/pages/timeline",
//     name: "timeline",
//     meta: { title: "Timeline", authRequired: false },
//     component: () => import("../views/pages/timeline"),
//   },
//   {
//     path: "/pages/faqs",
//     name: "FAQs",
//     meta: { title: "FAQs", authRequired: false },
//     component: () => import("../views/pages/faqs"),
//   },
//   {
//     path: "/pages/pricing",
//     name: "pricing",
//     meta: { title: "Pricing", authRequired: false },
//     component: () => import("../views/pages/pricing"),
//   },
//   {
//     path: "/pages/team",
//     name: "team",
//     meta: { title: "Team", authRequired: false },
//     component: () => import("../views/pages/team"),
//   },
//   {
//     path: "/pages/search-results",
//     name: "search-results",
//     meta: { title: "Search Results", authRequired: false },
//     component: () => import("../views/pages/search-results"),
//   },
//   {
//     path: "/pages/sitemap",
//     name: "sitemap",
//     meta: { title: "Sitemap", authRequired: false },
//     component: () => import("../views/pages/sitemap"),
//   },
//   {
//     path: "/pages/privacy-policy",
//     name: "privacy-policy",
//     meta: { title: "Privacy Policy", authRequired: false },
//     component: () => import("../views/pages/privacy-policy"),
//   },
//   {
//     path: "/pages/term-conditions",
//     name: "term-conditions",
//     meta: { title: "Term Conditions", authRequired: false },
//     component: () => import("../views/pages/term-conditions"),
//   },
//   {
//     path: "/charts/chartjs",
//     name: "chartjs-chart",
//     meta: { title: "Chartjs", authRequired: false },
//     component: () => import("../views/charts/chartjs/index"),
//   },
//   {
//     path: "/charts/echart",
//     name: "echart",
//     meta: { title: "Echarts", authRequired: false },
//     component: () => import("../views/charts/echart/index"),
//   },
//   {
//     path: '/charts/apex-line',
//     name: 'line',
//     meta: {
//       title: "Line Charts", authRequired: false,
//     },
//     component: () => import('../views/charts/apex/line/index')
//   },
//   {
//     path: '/charts/apex-area',
//     name: 'area-chart',
//     meta: {
//       title: "Area Charts", authRequired: false,
//     },
//     component: () => import('../views/charts/apex/area/index')
//   },
//   {
//     path: '/charts/apex-bar',
//     name: 'bar-chart',
//     meta: {
//       title: "Bar Charts", authRequired: false,
//     },
//     component: () => import('../views/charts/apex/bar/index')
//   },
//   {
//     path: '/charts/apex-column',
//     name: 'column-chart',
//     meta: {
//       title: "Column Charts", authRequired: false,
//     },
//     component: () => import('../views/charts/apex/column/index')
//   },

//   {
//     path: '/charts/apex-mixed',
//     name: 'mixed-chart',
//     meta: {
//       title: "Mixed Charts", authRequired: false,
//     },
//     component: () => import('../views/charts/apex/mixed/index')
//   },
//   {
//     path: '/charts/apex-candlestick',
//     name: 'candlestick',
//     meta: {
//       title: "Candlestick", authRequired: false,
//     },
//     component: () => import('../views/charts/apex/candlestick/index')
//   },
//   {
//     path: '/charts/apex-boxplot',
//     name: 'boxplot',
//     meta: {
//       title: "Boxplot", authRequired: false,
//     },
//     component: () => import('../views/charts/apex/boxplot/index')
//   },
//   {
//     path: '/charts/apex-bubble',
//     name: 'bubble',
//     meta: {
//       title: "Bubble Chart", authRequired: false,
//     },
//     component: () => import('../views/charts/apex/bubble/index')
//   },
//   {
//     path: '/charts/apex-scatter',
//     name: 'scatter',
//     meta: {
//       title: "Scatter", authRequired: false,
//     },
//     component: () => import('../views/charts/apex/scatter/index')
//   },
//   {
//     path: '/charts/apex-heatmap',
//     name: 'heatmap',
//     meta: {
//       title: "Heatmap", authRequired: false,
//     },
//     component: () => import('../views/charts/apex/heatmap/index')
//   },
//   {
//     path: '/charts/apex-treemap',
//     name: 'treemap',
//     meta: {
//       title: "Treemap", authRequired: false,
//     },
//     component: () => import('../views/charts/apex/treemap/index')
//   },
//   {
//     path: '/charts/apex-pie',
//     name: 'pie',
//     meta: {
//       title: "Apex Pie", authRequired: false,
//     },
//     component: () => import('../views/charts/apex/pie/index')
//   },
//   {
//     path: '/charts/apex-radialbar',
//     name: 'radialbar',
//     meta: {
//       title: "Apex Radialbar", authRequired: false,
//     },
//     component: () => import('../views/charts/apex/radiabar/index')
//   },
//   {
//     path: '/charts/apex-radar',
//     name: 'radar',
//     meta: {
//       title: "Apex Radar", authRequired: false,
//     },
//     component: () => import('../views/charts/apex/radar/index')
//   },
//   {
//     path: '/charts/apex-polararea',
//     name: 'polararea',
//     meta: {
//       title: "Polararea", authRequired: false,
//     },
//     component: () => import('../views/charts/apex/polararea/index')
//   },
//   {
//     path: '/advance-ui/animation',
//     name: 'animation',
//     meta: {
//       title: "Animation", authRequired: false,
//     },
//     component: () => import('../views/advance-ui/animation')
//   },
//   {
//     path: '/advance-ui/highlight',
//     name: 'highlight',
//     meta: {
//       title: "Highlight", authRequired: false,
//     },
//     component: () => import('../views/advance-ui/highlight')
//   },
//   {
//     path: '/advance-ui/scrollbar',
//     name: 'scrollbar',
//     meta: {
//       title: "Scrollbar", authRequired: false,
//     },
//     component: () => import('../views/advance-ui/scrollbar')
//   },
//   {
//     path: '/advance-ui/scrollspy',
//     name: 'scrollspy',
//     meta: {
//       title: "Scrollspy", authRequired: false,
//     },
//     component: () => import('../views/advance-ui/scrollspy')
//   },
//   {
//     path: '/advance-ui/sweetalerts',
//     name: 'sweetalerts',
//     meta: {
//       title: "Sweet Alerts", authRequired: false,
//     },
//     component: () => import('../views/advance-ui/sweetalerts')
//   },
//   {
//     path: '/advance-ui/swiper',
//     name: 'Swiper Slider',
//     meta: {
//       title: "Swiper", authRequired: false,
//     },
//     component: () => import('../views/advance-ui/swiper')
//   },
//   {
//     path: '/auth/signin-basic',
//     name: 'signin-basic',
//     meta: {
//       title: "Signin Basic", authRequired: false,
//     },
//     component: () => import('../views/auth/signin/basic')
//   },
//   {
//     path: '/auth/signin-cover',
//     name: 'signin-cover',
//     meta: {
//       title: "Signin Cover", authRequired: false,
//     },
//     component: () => import('../views/auth/signin/cover')
//   },
//   {
//     path: '/auth/signup-basic',
//     name: 'signup-basic',
//     meta: {
//       title: "Signup Basic", authRequired: false,
//     },
//     component: () => import('../views/auth/signup/basic')
//   },
//   {
//     path: '/auth/signup-cover',
//     name: 'signup-cover',
//     meta: {
//       title: "Signup Cover", authRequired: false,
//     },
//     component: () => import('../views/auth/signup/cover')
//   },
//   {
//     path: '/auth/reset-pwd-basic',
//     name: 'reset-pwd-basic',
//     meta: {
//       title: "Reset Password", authRequired: false,
//     },
//     component: () => import('../views/auth/reset/basic')
//   },
//   {
//     path: '/auth/reset-pwd-cover',
//     name: 'reset-pwd-cover',
//     meta: {
//       title: "Reset Password", authRequired: false,
//     },
//     component: () => import('../views/auth/reset/cover')
//   },
//   {
//     path: '/auth/create-pwd-basic',
//     name: 'create-pwd-basic',
//     meta: {
//       title: "create Password", authRequired: false,
//     },
//     component: () => import('../views/auth/create/basic')
//   },
//   {
//     path: '/auth/create-pwd-cover',
//     name: 'create-pwd-cover',
//     meta: {
//       title: "create Password", authRequired: false,
//     },
//     component: () => import('../views/auth/create/cover')
//   },
//   {
//     path: '/auth/lockscreen-basic',
//     name: 'lock-screen-basic',
//     meta: {
//       title: "Lock Screen", authRequired: false,
//     },
//     component: () => import('../views/auth/lockscreen/basic')
//   },
//   {
//     path: '/auth/lockscreen-cover',
//     name: 'lock-screen-cover',
//     meta: {
//       title: "Lock Screen", authRequired: false,
//     },
//     component: () => import('../views/auth/lockscreen/cover')
//   },
//   {
//     path: '/auth/twostep-basic',
//     name: 'twostep-basic',
//     meta: {
//       title: "Two Step Auth", authRequired: false,
//     },
//     component: () => import('../views/auth/twostep/basic')
//   }, {
//     path: '/auth/twostep-cover',
//     name: 'twostep-cover',
//     meta: {
//       title: "Two Step Auth", authRequired: false,
//     },
//     component: () => import('../views/auth/twostep/cover')
//   },
  {
    path: '/auth/404',
    name: '404',
    meta: {
      title: "Error 404", authRequired: false,
    },
    component: () => import('../views/auth/errors/404')
  },
//   {
//     path: '/auth/403',
//     name: '403',
//     meta: {
//       title: "Error 403", authRequired: false,
//     },
//     component: () => import('../views/auth/errors/403')
//   },
//   {
//     path: '/auth/500',
//     name: '500',
//     meta: {
//       title: "Error 500", authRequired: false,
//     },
//     component: () => import('../views/auth/errors/500')
//   },
//   {
//     path: '/auth/404-basic',
//     name: '404-basic',
//     meta: {
//       title: "Error 404", authRequired: false,
//     },
//     component: () => import('../views/auth/errors/404-basic')
//   },
//   {
//     path: '/auth/404-cover',
//     name: '404-cover',
//     meta: {
//       title: "Error 404", authRequired: false,
//     },
//     component: () => import('../views/auth/errors/404-cover')
//   },
//   {
//     path: '/auth/ofline',
//     name: 'ofline',
//     meta: {
//       title: "Offline", authRequired: false,
//     },
//     component: () => import('../views/auth/errors/ofline')
//   },
//   {
//     path: '/auth/logout-basic',
//     name: 'logout-basic',
//     meta: {
//       title: "Logout", authRequired: false,
//     },
//     component: () => import('../views/auth/logout/basic')
//   },
//   {
//     path: '/auth/logout-cover',
//     name: 'logout-cover',
//     meta: {
//       title: "Logout", authRequired: false,
//     },
//     component: () => import('../views/auth/logout/cover')
//   },
//   {
//     path: '/auth/success-msg-basic',
//     name: 'success-msg-basic',
//     meta: {
//       title: "Success Message", authRequired: false,
//     },
//     component: () => import('../views/auth/success-msg/basic')
//   },
//   {
//     path: '/auth/success-msg-cover',
//     name: 'success-msg-cover',
//     meta: {
//       title: "Success Message", authRequired: false,
//     },
//     component: () => import('../views/auth/success-msg/cover')
//   },
//   {
//     path: "/maps/google",
//     name: "Google Maps",
//     meta: {
//       title: "Google Maps", authRequired: false,
//     },
//     component: () => import("../views/maps/google"),
//   },
//   {
//     path: "/maps/leaflet",
//     name: "Leaflet Maps",
//     meta: { title: "Leaflet Maps", authRequired: false },
//     component: () => import("../views/maps/leaflet/index"),
//   },
//   {
//     path: "/department",
//     name: "Department",
//     meta: { title: "Department", authRequired: false },
//     component: () => import("../pages/academic_structure/departments/Departments"),
//   },
//   {
//     path: "/programs",
//     name: "Programs",
//     meta: { title: "Programs", authRequired: false },
//     component: () => import("../pages/academic_structure/departments/Programs"),
//   },
//   {
//     path: "/createcourse",
//     name: "createcourse",
//     meta: { title: "createcourse", authRequired: true, requiredPermission:'create course' },
//     component: () => import("../pages/course/CreateCourse"),
//   },  
//   {
//     path: "/coursemanagement",
//     name: "coursemanagement",
//     meta: { title: "coursemanagement", authRequired: true, requiredPermission:'view courses' },
//     component: () => import("../pages/course/CourseManagement"),
//   },  
//   {
//     path: "/timetables",
//     name: "TimeTable",

//     meta: {title: "overview",authRequired: true,requiredPermission:'timetable'},
//     component: () => import("../pages/course/timetable/TimeTable"),
//   },  
//   {
//     path: "/upload-timetable",
//     name: "UploadTimeTable",

//     meta: {title: "overview",authRequired: true,requiredPermission:'timetable'},
//     component: () => import("../pages/course/timetable/UploadTimeTable"),
//   },  
//   // {
//   //   path: "/log",
//   //   name: "log",
//   //   meta: { title: "Log", authRequired: false },
//   //   component: () => import("../pages/lecturer_portal/courses/Log.vue"),
//   // },
//   {
//     path: "/coursesingle/:id/",
//     name: "coursesingle",
//     meta: { title: "coursesingle", authRequired: true, requiredPermission:'view course' },
//     component: () => import("../pages/course/CourseSingle"),
//     children:[
//       {
//         path:"",
//         name:"course-overview",
//         component: () => import("../pages/course/CourseOverview")
//       },
//       {
//         path:"lecturer",
//         name:"lecturer-course",
//         component: () => import("../pages/course/AssignLecturer")
//       },
//       {
//         path:"courseList",
//         name:"studentcourselist",
//         component: () => import("../pages/course/StudentCourseList")
//       },
//     ]
//   }, 
//   {
//     path: "/courseUpload",
//     name: "courseUpload",
//     meta: { title: "courseUpload", authRequired: true, requiredPermission:'view courses' },
//     component: () => import("../pages/course/CourseUpload"),
//   },  
//   {
//     path: "/studentcourselist",
//     name: "StudentCourseList",
//     meta: { title: "StudentCourseList", authRequired: false },
//     component: () => import("../pages/course/StudentCourseList"),
//   }, 
//   {
//     path: "/list",
//     name: "schoolcourselist",
//     meta: { title: "schoolcourselist", authRequired: false },
//     component: () => import("../pages/course/SchoolCourseList"),
//   },   
//   {
//     path: "/results/courseresult",
//     name: "courseresult",
//     meta: { title: "CourseResult", authRequired: false },
//     component: () => import("../pages/results_and_transcript/CourseResult"),
//   },  
//   {
//     path: "/results/courses",
//     name: "Course-Result",
//     meta: { title: "Courses", authRequired: false },
//     component: () => import("../pages/results_and_transcript/Courses"),
//   },  
//   {
//     path: "/results/coursetype",
//     name: "coursetype",
//     meta: { title: "CourseType", authRequired: false },
//     component: () => import("../pages/course/CourseType"),
//   }, 
//   {
//     path: "/results/resultupload",
//     name: "ResultUpload",
//     meta: { title: "ResultUpload", authRequired: false },
//     component: () => import("../pages/results_and_transcript/ExamUpload"),
//   },   
//   {
//     path: "/results/examcodeupload",
//     name: "ExamCodeUpload",
//     meta: { title: "ResultUpload", authRequired: false },
//     component: () => import("../pages/results_and_transcript/ExamCodeUpload"),
//   },
//   {
//     path: "/results/caupload/:id",
//     name: "CAUpload",
//     meta: { title: "CAUpload", authRequired: false },
//     component: () => import("../pages/results_and_transcript/CaUpload"),
//   },
//   {
//     path: "/results/markstate",
//     name: "MarkState",
//     meta: { title: "MarkState", authRequired: false },
//     component: () => import("../pages/results_and_transcript/MarkState"),
//   },
//   {
//     path: "/results/:id",
//     name: "result-Profile",
//     meta: {title:'Results', authRequired: true, requiredPermission:'view results'},
//     component: () => import("../pages/results_and_transcript/Index.vue"),
//     children:[
//       {
//         path:"",
//         name:"result-overview",
//         component: () => import("../pages/results_and_transcript/Overview.vue")
//       },//sort this out as soon as possible
//       {
//         path:"ca-upload",
//         name:"ca-upload",
//         component: () => import("../pages/results_and_transcript/CaUpload")
//       },
//       {
//         path:"exam-code-upload",
//         name:"result-code-upload",
//         component: () => import("../pages/results_and_transcript/ExamCodeUpload.vue")
//       },
//       {
//         path:"exam-upload",
//         name:"exam-upload",
//         component: () => import("../pages/results_and_transcript/ExamUpload.vue")
//       },
//       {
//         path:"result",
//         name:"result",
//         component: () => import("../pages/results_and_transcript/CourseResult.vue")
//       },
//       {
//         path:"stats",
//         name:"Results Statistics",
//         component: () => import("../pages/results_and_transcript/ResultStats.vue")
//       },

      
//     ]
//   }, 
//   {
//     path: "/complains",
//     name: "complains",
//     meta: { title: "complains", authRequired: false },
//     component: () => import("../pages/complains/Complains"),
//   },
//   {
//     path: "/complainsingle/:id",
//     name: "complainsingle",
//     meta: { title: "complains", authRequired: false },
//     component: () => import("../pages/complains/ComplainSingle"),
//   },
//   {
//     path: "/transcript",
//     name: "transcript",
//     meta: { title: "transcript", authRequired: false },
//     component: () => import("../pages/transcript_and_results/Transcript"),
//   },
//   {
//     path: "/transcript-view/:matricule/:ay_id",
//     name: "transcript-view",
//     meta: { title: "transcript application view", authRequired: false },
//     component: () => import("../pages/transcript_and_results/components/ApplicationSingle"),
//   },
//   {
//     path: "/draft",
//     name: "drafttranscript",
//     meta: { title: "drafttranscript", authRequired: false },
//     component: () => import("../pages/transcript_and_results/DraftTranscript"),
//   },
//   {
//     path: "/applications",
//     name: "applications",
//     meta: { title: "applications", authRequired: false },
//     component: () => import("../pages/transcript_and_results/Applications"),
//   },
//   {
//     path: "/attestation",
//     name: "attestation",
//     meta: { title: "attestation", authRequired: false },
//     component: () => import("../pages/transcript_and_results/Attestation"),
//   },
//   {
//     path: "/gradlist",
//     name: "graduautionlist",
//     meta: { title: "graduautionlist", authRequired: false },
//     component: () => import("../pages/transcript_and_results/GraduationList"),
//   },
//   {
//     path: "/lecturerportal/courses",
//     name: "lecturerportcourses",
//     meta: { title: "lecturerportal", authRequired: false },
//     component: () => import("../pages/lecturer_portal/courses/Courses"),
//   },
//   {
//     path: "/lecturerportal/coursesingle/:id/",
//     name: "lecturerportcoursesingle",
//     meta: { title: "lecturerportal", authRequired: false },
//     component: () => import("../pages/lecturer_portal/courses/Coursesingle"),
//   },
//   {
//     path: "/lecturerportal/classes/:id/",
//     name: "lecturerportclasses",
//     meta: { title: "lecturerportal", authRequired: false },
//     component: () => import("../pages/lecturer_portal/Classes"),
//   },
//   {
//     path: "/lecturerportal/marks",
//     name: "lecturerPortalmarks",
//     meta: { title: "lecturerportal", authRequired: false },
//     component: () => import("../pages/lecturer_portal/mark/Marks.vue"),
//   },
//   {
//     path: "/lecturerportal/examsingle/:id/",
//     name: "lecturerPortalexamsingle",
//     meta: { title: "lecturerportal", authRequired: false },
//     component: () => import("../pages/lecturer_portal/exam/ExamSingle"),
//   },

//   {
//     path: "/lecturerportal/marksingle/:id/",
//     name: "lecturerPortalmarksingle",
//     meta: { title: "lecturerportal", authRequired: false },
//     component: () => import("../pages/lecturer_portal/mark/MarkSingle"),
//   },
//   {
//     path: "/lecturerportal/payroll",
//     name: "lecturerportpayroll",
//     meta: { title: "lecturerportal", authRequired: false },
//     component: () => import("../pages/lecturer_portal/Payroll"),
//   },

//   {
//     path: "/studentnotification",
//     name: "studentnotification",
//     meta: { title: "notification", authRequired: false },
//     component: () => import("../pages/notifications/StudentNotification"),
//   },
//   {
//     path: "/send-bulk-notification",
//     name: "SendBulkNotification",
//     meta: { title: "Send Bulk Notification", authRequired: false },
//     component: () => import("../pages/notifications/components/SendBulkNotification"),
//   },
//   {
//     path: "/bulk-notifications",
//     name: "BulkNotification",
//     meta: { title: "Bulk Notification", authRequired: false },
//     component: () => import("../pages/notifications/BulkNotifications"),
//   },
//   {
//     path: "/send-student-notification",
//     name: "sendstudentnotification",
//     meta: { title: "Send Student Notification", authRequired: false },
//     component: () => import("../pages/notifications/components/SendStudentNotification"),
//   },
//   {
//     path: "/send-lecturer-notification",
//     name: "sendlecturersnotification",
//     meta: { title: "Send Lecturer Notification", authRequired: false },
//     component: () => import("../pages/notifications/components/SendLecturerNotification"),
//   },
//   {
//     path: "/lecturernotification",
//     name: "lecturernotification",
//     meta: { title: "lecturernotification", authRequired: false },
//     component: () => import("../pages/notifications/LecturerNotification"),
//   },
//   {
//     path: "/departmentalnotification",
//     name: "departmentalnotification",
//     meta: { title: "departmentalnotification", authRequired: false },
//     component: () => import("../pages/notifications/DepartmentalNotification"),
//   },
//   {
//     path: "/notification-pricing",
//     name: "NotificationPricing",
//     meta: { title: "Notification Pricing", authRequired: false },
//     component: () => import("../pages/notifications/Pricing"),
//   },
//   {
//     path: "/payments-made",
//     name: "payments-made",
//     meta: { title: "payments-made", authRequired: false },
//     component: () => import("../pages/payment_and_finance/payments/Payments"),
//   },
//   {
//     path: "/feestatus",
//     name: "feestatus",
//     meta: { title: "feestatus", authRequired: false },
//     component: () => import("../pages/payment_and_finance/FeeStatus"),
//   },
//   {
//     path: "/fee/:id",
//     name: "fee",
//     meta: { title: "fee", authRequired: false },
//     component: () => import("../pages/payment_and_finance/Fee"),
//   },
//   {
//     path: "/feestats",
//     name: "feestats",
//     meta: { title: "feestatistics", authRequired: false },
//     component: () => import("../pages/payment_and_finance/FeeStatistics"),
//   },
//   {
//     path: "/feevalidation",
//     name: "feevalidation",
//     meta: { title: "feevalidation", authRequired: false },
//     component: () => import("../pages/payment_and_finance/FeeValidation"),
//   },
//   {
//     path: "/feevalidsingle",
//     name: "feevalidationsingle",
//     meta: { title: "feevalidationsingle", authRequired: false },
//     component: () => import("../pages/payment_and_finance/FeeValidationSingle"),
//   },
//   {
//     path: "/fees",
//     name: "fees",
//     meta: { title: "fees", authRequired: false },
//     component: () => import("../pages/payment_and_finance/Fees"),
//   },
//   {
//     path: "/lecturers",
//     name: "lecturers",
//     meta: { title: "lecturers", authRequired: false },
//     component: () => import("../pages/hr_and_payroll/Lecturers"),
//   },
//   {
//     path: "/lecturer/:id",
//     name: "lecturer",
//     meta: { title: "lecturer", authRequired: false },
//     component: () => import("../pages/hr_and_payroll/lecturer/Lecturer"),
//   },
//   {
//     path: "/lecturer/:id/payroll",
//     name: "lecturer payroll",
//     meta: { title: "lecturer", authRequired: false },
//     component: () => import("../pages/hr_and_payroll/lecturer/Payroll"),
//   },
//   {
//     path: "/employees",
//     name: "employees",
//     meta: { title: "employees", authRequired: false },
//     component: () => import("../pages/hr_and_payroll/Employees"),
//   },
//   {
//     path: "/employee/:id",
//     name: "employee",
//     meta: { title: "employeee", authRequired: false },
//     component: () => import("../pages/hr_and_payroll/employee/Employee"),
//   },
//   {
//     path: "/payment",
//     name: "payment",
//     meta: { title: "payment", authRequired: false },
//     component: () => import("../pages/hr_and_payroll/PaymentStatus"),
//   },
//   {
//     path: "/budgetandforecasting",
//     name: "budgetandforecasting",
//     meta: { title: "budgetandforecasting", authRequired: false },
//     component: () => import("../pages/accounting_and_finance/financial_management/BudgetAndForecasting"),
//   },
//   {
//     path: "/accounts",
//     name: "accounts",
//     meta: { title: "accounts", authRequired: false },
//     component: () => import("../pages/accounting_and_finance/financial_management/Accounts"),
//   },
//   {
//     path: "/accounts/:id",
//     name: "accountsSingle",
//     meta: { title: "accounts Single", authRequired: false },
//     component: () => import("../pages/accounting_and_finance/financial_management/AccountSingle"),
//   },
//   {
//     path: "/expensetracking",
//     name: "expensetracking",
//     meta: { title: "expensetracking", authRequired: false },
//     component: () => import("../pages/accounting_and_finance/financial_management/ExpenseTracking"),
//   },
//   {
//     path: "/expensecategory",
//     name: "expensecategory",
//     meta: { title: "expensecategory", authRequired: false },
//     component: () => import("../pages/accounting_and_finance/financial_management/ExpenseCategory"),
//   },
//   {
//     path: "/revenueManagement",
//     name: "revenueManagement",
//     meta: { title: "revenueManagement", authRequired: false },
//     component: () => import("../pages/accounting_and_finance/financial_management/RevenueManagement"),
//   },
//   {
//     path: "/campus",
//     name: "campus",
//     meta: { title: "campus", authRequired: false },
//     component: () => import("../pages/academic_structure/Campus"),
//   },
//   {
//     path: "/levels",
//     name: "level",
//     meta: { title: "level", authRequired: false },
//     component: () => import("../pages/academic_structure/programs/Levels"),
//   },

//   // {
//   //   path: '/students',
//   //   component: () => import('@/pages/test/index.vue')
//   // },
];